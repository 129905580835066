import React from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import { Builders } from 'q3-ui-forms';
import {
  Box,
  Grid,
  Paper,
  Button,
} from '@material-ui/core';
import { connect } from 'q3-admin/lib/containers';
// eslint-disable-next-line
import { Adapters } from 'q3-ui-rte';
import { get, map } from 'lodash';
import Comments from 'q3-ui-comments';
import { useTranslation } from 'q3-ui-locale';
import { string } from 'q3-ui-helpers';
import WidgetTitle from 'q3-admin/lib/components/WidgetTitle';
import { browser } from 'q3-ui-helpers';
import CallMadeIcon from '@material-ui/icons/CallMade';
import withNotificationsAnalytics from 'q3-admin/lib/helpers/withNotificationsAnalytics';
import { compact } from 'lodash';
import JournalCourseInstructions from '../JournalCourseInstructions';
import useStyle from './styles';
import JournalActions from '../JournalActions';
import { PRIVATE } from '../Journals/constants';
import ReadOnlyTemplate from '../ReadOnlyTemplate';
import ReadOnlyTemplateAccordion from '../ReadOnlyTemplateAccordion';
import JournalsAttributesReadOnly from '../JournalsAttributesReadOnly';
import useCommentHash from '../useCommentHash';

// eslint-disable-next-line
export const CommentBox = ({ id }) => {
  const cls = useStyle();
  useCommentHash();

  return (
    <Box id="comments" className={cls.comments}>
      <Comments collectionName="journals" id={id} />
    </Box>
  );
};

export const JournalsGeneral = ({ data, ...rest }) => {
  const published = !data?.publishedOn;
  const publishedState = data?.publishedState;
  const [editor, setEditor] = React.useState(published);
  const cls = useStyle();
  const { t } = useTranslation('titles');
  const courseTitle = data?.course?.title;

  const assignmentTitle =
    compact([data?.prompt, courseTitle]).join(' | ') ||
    t('homework');

  const handleScrollPositionOnClick = ({
    currentTarget,
  }) => {
    if (currentTarget)
      currentTarget.scrollIntoView({
        behavior: 'smooth',
      });
  };

  React.useEffect(() => {
    setEditor(published);
  }, [published]);

  React.useLayoutEffect(() => {
    // eslint-disable-next-line
    if (!data.isMine && browser.isBrowserReady()) {
      const el = document.getElementById(
        'detail-action--trash',
      );

      if (el) el.style = 'display:none';
    }
  }, []);

  return (
    <ReadOnlyTemplate noGutters>
      <Box id="zoom" className={cls.wrapper}>
        <ReadOnlyTemplateAccordion title={assignmentTitle}>
          <Box px={1.5} className={cls.cleanup}>
            <JournalCourseInstructions {...data} />
          </Box>
          {courseTitle && (
            <Button
              component={Link}
              to={`/app/journals?course.title=string(${data?.course?.title})`}
              endIcon={<CallMadeIcon />}
            >
              {t('labels:seeAllPostsForThisCourse')}
            </Button>
          )}
        </ReadOnlyTemplateAccordion>

        <Paper elevation={0}>
          <Box p={1}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
            >
              <WidgetTitle text="journal" />
              <Box display="flex">
                <JournalsAttributesReadOnly />
              </Box>
            </Box>
            <Box px={1.5}>
              {editor ? (
                <div
                  onClick={handleScrollPositionOnClick}
                  onKeyPress={handleScrollPositionOnClick}
                  className={cls.root}
                  role="button"
                  tabIndex={-1}
                >
                  <Builders.Form
                    {...rest}
                    submitLabel="saveDraft"
                    initialValues={data}
                    keep={['body']}
                    enableSubmit={false}
                    disableChangeDetection={false}
                  >
                    <Builders.Field
                      upload={
                        data
                          ? Adapters.toQ3Api(
                              `/journals/${data.id}`,
                            )
                          : undefined
                      }
                      name="body"
                      type="editor"
                      xl={12}
                      lg={12}
                    />
                    <Grid
                      item
                      xs={12}
                      style={{ marginTop: '-1rem' }}
                    >
                      <JournalActions />
                    </Grid>
                    <button
                      aria-label="submit"
                      id="journal-submit"
                      style={{ visibility: 'hidden' }}
                      type="submit"
                    />
                  </Builders.Form>
                </div>
              ) : (
                <>
                  <small>
                    Written by{' '}
                    {string.makeName(data?.createdBy)} on{' '}
                    {string.toDate(
                      // eslint-disable-next-line
                      data?.publishedOn || data?.updatedAt,
                    )}
                    {data?.publishedState && (
                      <>{` • ${data?.publishedState}`}</>
                    )}
                  </small>

                  <Box
                    className={cls.cleanup}
                    dangerouslySetInnerHTML={{
                      __html: data?.body,
                    }}
                    mb={2}
                  />
                </>
              )}
              {!editor && publishedState !== PRIVATE && (
                <CommentBox id={data.id} />
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
      {editor && <Box mb="10vh" />}
    </ReadOnlyTemplate>
  );
};

JournalsGeneral.propTypes = {
  data: PropTypes.shape({
    body: PropTypes.string,
    publishedOn: PropTypes.string,
    id: PropTypes.string,
    publishedState: PropTypes.string,
    prompt: PropTypes.string,
    course: PropTypes.shape({
      title: PropTypes.string,
    }),
    createdBy: PropTypes.shape({
      firstName: PropTypes.string,
    }),
  }).isRequired,
};

const MainNotifications =
  withNotificationsAnalytics(JournalsGeneral);

const CommentNotifications = withNotificationsAnalytics(
  MainNotifications,
  (data) => map(get(data, 'comments', []), 'id'),
);

export default connect(CommentNotifications);
